import React, { useState, useEffect } from 'react';
import { ReactComponent as CalendarIcon } from 'assets/svg/calendar.svg';
import { ReactComponent as LocationIcon } from 'assets/svg/location.svg';
import Fallback from 'components/Fallback';
import { fetchConcerts } from 'api';

export default function ConcertsComp() {
  const [concerts, setConcerts] = useState([])
  const [loading, setLoading] = useState(false)
  // const apiKey = 'ea7ee317-96ac-c32f-ff8c-11c358ca5afb';

  const getDate = (dateStr) => {
    // can be 'dd.mm.YYYY' or 'dd.mm.YYYY - dd.mm.YYYY
    let firstDateStr = dateStr.split('-')[0];
    firstDateStr = firstDateStr.replace(/\s/g, '');
    const parts = firstDateStr.split('.');
    return new Date(parts[2], parts[1] - 1, parts[0]);
  }

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true)
      const data = await fetchConcerts();
      if (data && data.data && Array.isArray(data.data)) {
        // sort by date
        const concerts = data.data;
        const concertsWithDates = [];
        concerts.forEach(c => {
          const newConcert = c;
          newConcert.dateObj = getDate(c.date);
          concertsWithDates.push(newConcert)
        });
        concertsWithDates.sort(function(a, b) {
          return a.dateObj - b.dateObj;
        });
        setConcerts(concertsWithDates);
      }
      setLoading(false)
    }
    fetchData();
  }, []);

  function Concert({data}) {
    const { date, time, city, place, price, link, pushkinskayaLink, openInANewPage } = data;

    const linkWidget = link;

    return (
      <div className="concert">
        {/* Date */}
        <div className="concert__date-container">
          <CalendarIcon className="concert__date-icon"/>
          <div className="concert__date">
            <div className="concert__date-dmy">
              { date }
            </div>
            <div className="concert__date-time">
              { time }
            </div>
          </div>
        </div>
        {/* Place */}
        <div className="concert__location-container">
          <LocationIcon className="concert__location-icon"/>
          <div className="concert__location">
            <div className="concert__location-city">
              { city }
            </div>
            <div className="concert__location-place">
              { place }
            </div>
          </div>
        </div>
        {/* Cost */}
        <div className="concert__price">
          <div className="concert__price-label"> Цена </div>
          <div className="concert__price-amount">
            { price }
          </div>
        </div>
        { !!pushkinskayaLink && 
            (<a target={openInANewPage ? '_blank' : '_self'} className="concert__btn knopka" href={pushkinskayaLink}
            // onClick={() => {
            //   window.kassirWidget.summon({url: linkWidget})}
            // }
            >
              Купить по Пушкинской карте
            </a>)
        }
        {/* Buy */}
          <a target={openInANewPage ? '_blank' : '_self'} className="concert__btn" href={linkWidget}
          // onClick={() => {
          //   window.kassirWidget.summon({url: linkWidget})}
          // }
          >
            { !!pushkinskayaLink ? 'Купить по обычной карте' : 'Купить билет'}
          </a>
        {/* </div> */}
      </div>
    )
  }
  return (
    <div className="concerts">
      <div className="concerts__title"> Концерты</div>
      { loading ? <Fallback /> : ''}
      { concerts && concerts.map((c, i) => <Concert key={i} data={c} />) }
    </div>
  )
}
